import { Spin } from "antd";

import "antd/dist/antd.css";
import {
  useContractLoader,
  useContractReader,
  useGasPrice,
  // useOnBlock,
  useUserProviderAndSigner,
} from "eth-hooks";
import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import { NETWORKS } from "./constants";
import externalContracts from "./contracts/external_contracts";
// contracts
import deployedContracts from "./contracts/hardhat_contracts.json";
import { getRPCPollTime, Transactor, Web3ModalSetup } from "./helpers";
import { useStaticJsonRPC } from "./hooks";
import Home from "./app/index";
import Main from "./pages/main";
import Auth from "./pages/auth";
import { LoadingOutlined } from "@ant-design/icons";

const { ethers } = require("ethers");
const initialNetwork = NETWORKS.polygon;
// 😬 Sorry for all the console logging
const DEBUG = true;
const USE_BURNER_WALLET = false; // toggle burner wallet feature

const web3Modal = Web3ModalSetup();

const networkOptions = [initialNetwork.name, "mainnet", "goerli"];
const selectedNetwork = networkOptions[0];

function App(props) {
  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();

  const [registerState, setRegisterState] = useState();
  const [queryRegisterStateLoading, setQueryRegisterStateLoading] = useState(true);

  const targetNetwork = NETWORKS[selectedNetwork];

  // 🔭 block explorer URL
  const blockExplorer = targetNetwork.blockExplorer;

  // load all your providers
  const localProvider = useStaticJsonRPC([targetNetwork.rpcUrl]);

  // Sensible pollTimes depending on the provider you are using
  const localProviderPollingTime = getRPCPollTime(localProvider);

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  // const price = useExchangeEthPrice(targetNetwork, mainnetProvider, mainnetProviderPollingTime);

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "fast", localProviderPollingTime);
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider, USE_BURNER_WALLET);
  const userSigner = userProviderAndSigner.signer;

  useEffect(() => {
    async function getAddress() {
      try {
        if (userSigner) {
          setQueryRegisterStateLoading(true);
          const newAddress = await userSigner.getAddress();
          setAddress(newAddress);
        }
      } catch (e) {
      } finally {
        // setAddressLoading(false);
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, gasPrice);

  // const contractConfig = useContractConfig();

  const contractConfig = {
    deployedContracts: deployedContracts || {},
    externalContracts: externalContracts || {},
  };

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);

  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  useEffect(() => {
    if (DEBUG && address && selectedChainId && readContracts && writeContracts) {
      console.log("_____________________________________ 🏗 scaffold-eth _____________________________________");
      console.log("🏠 localChainId", localChainId);
      console.log("👩‍💼 selected address:", address);
      console.log("🕵🏻‍♂️ selectedChainId:", selectedChainId);
      console.log("📝 readContracts", readContracts);
      console.log("🔐 writeContracts", writeContracts);
    }
  }, [address, selectedChainId, readContracts, writeContracts, localChainId]);

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
      setTimeout(() => {
        window.location.reload();
      }, 1);
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
      setTimeout(() => {
        window.location.reload();
      }, 1);
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  useEffect(() => {
    const fetch = async () => {
      try {
        if (readContracts?.USDG?.registerState) {
          const registerStateResult = await readContracts?.USDG?.registerState(address);
          setRegisterState(registerStateResult);
          setQueryRegisterStateLoading(false);
        }
      } catch (e) {
        setQueryRegisterStateLoading(false);
      }
    };
    fetch();
  }, [address, readContracts]);
  return (
    <Spin
      className="App"
      spinning={queryRegisterStateLoading}
      indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />}
    >
      <div style={{ height: "100vh" }}>
        <Switch>
          <Route path="/main">
            <Auth address={address}>
              <Main
                address={address}
                readContracts={readContracts}
                localProviderPollingTime={localProviderPollingTime}
                writeContracts={writeContracts}
                tx={tx}
                blockExplorer={blockExplorer}
              />
            </Auth>
          </Route>
          <Route exact path="/">
            <Home
              loadWeb3Modal={loadWeb3Modal}
              useContractReader={useContractReader}
              readContracts={readContracts}
              address={address}
              localProviderPollingTime={localProviderPollingTime}
              tx={tx}
              writeContracts={writeContracts}
              registerState={registerState}
            />
          </Route>
        </Switch>
      </div>
    </Spin>
  );
}

export default App;
