import "./index.css";
import { Row, Col, Button, Select, InputNumber, notification, Typography, Spin } from "antd";
import logo from "../../images/logo.png";
// import wallet from "../../images/wallet.png";
// import copyIcon from "../../images/copy.png";
// import swapIcon from "../../images/swap.png";
import arrowIcon from "../../images/arrow.png";
import { useCallback, useEffect, useMemo, useState } from "react";
import NavLanguageSelector from "../../components/NavLanguageSelector";
import { useContractReader } from "eth-hooks";
import { utils } from "ethers";
import { useTranslation } from "react-i18next";
import { ShareAltOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import powerIcon from "../../images/power.png";
import powerNoIcon from "../../images/power_no.png";
import batteryIcon from "../../images/battery.png";
import batteryNoIcon from "../../images/battery_no.png";
import { LoadingOutlined } from "@ant-design/icons";

const { ethers } = require("ethers");

function formatDisplayValue(value) {
  return value ? Number(utils.formatEther(value)).toFixed(2) : "...";
}

//for usdt with decimal=6
function formatUSDTValue(value) {
  return value ? Number(utils.formatUnits(value, 6)).toFixed(2) : "...";
}
const blockExplorerLink = (address, blockExplorer) => `${blockExplorer || "https://etherscan.io/"}address/${address}`;

const formatDatetime = value => {
  return Number(value) ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "-";
};

function Main({ address, readContracts, localProviderPollingTime, writeContracts, tx, blockExplorer }) {
  const { t } = useTranslation();
  const pools = [t("青铜"), t("白银"), t("黄金"), t("白金"), t("钻石")];
  const rings = [t("一环"), t("二环"), t("三环"), t("四环"), t("五环"), t("六环")];
  const displayAddress = useMemo(() => {
    return address?.substr(0, 5) + "..." + address?.substr(-4);
  }, [address]);
  const [approveLoading, setApproveLoading] = useState();
  const [isApproved, setIsApproved] = useState();
  const [newUsdtAmount, setNewUsdtAmount] = useState(1080);
  const [depositField, setDepositField] = useState(1);
  const [newUsdtLoading, setNewUsdtLoading] = useState();
  const [claimLoading, setClaimLoading] = useState();
  const [usdtclaimLoading, setUsdtClaimLoading] = useState();
  const [usdgclaimLoading, setUsdgClaimLoading] = useState();
  const [newUsdgAmount, setNewUsdgAmount] = useState(120);
  const [swapLoading, setSwapLoading] = useState();
  const [revenueCalculate, setRevenueCalculate] = useState(0);
  const [userSpeedUpCards, setUserSpeedUpCards] = useState();
  const [userSpeedUpPools, setUserSpeedUpPools] = useState();
  const [speedLoading, setSpeedLoading] = useState(false);

  const usdtBalance = useContractReader(readContracts, "USDT", "balanceOf", [address], localProviderPollingTime);
  const applierState = useContractReader(readContracts, "USDG", "applierState", [address], localProviderPollingTime);

  const deposit = useContractReader(readContracts, "USDG", "totalDepositValue", [address], localProviderPollingTime);
  const totalRevenue = useContractReader(readContracts, "USDG", "totalRevenue", [address], localProviderPollingTime);

  const usdtShare = useContractReader(readContracts, "USDG", "USDTShareReward", [address], localProviderPollingTime);
  const usdgShare = useContractReader(readContracts, "USDG", "USDGShareReward", [address], localProviderPollingTime);

  const totalclaimedRevenue = useContractReader(
    readContracts,
    "USDG",
    "totalclaimedRevenue",
    [address],
    localProviderPollingTime,
  );
  const usdgBalance = useContractReader(readContracts, "USDG", "balanceOf", [address], localProviderPollingTime);
  const approveUsdtAmount = useContractReader(
    readContracts,
    "USDT",
    "allowance",
    [address, readContracts.USDG?.address],
    localProviderPollingTime,
  );
  useEffect(() => {
    if (approveUsdtAmount && ethers.constants.MaxUint256.eq(approveUsdtAmount)) {
      setIsApproved(true);
    }
  }, [approveUsdtAmount]);

  const field1 = useContractReader(readContracts, "USDG", "fieldsDepositValue", [address, 0], localProviderPollingTime);
  const field2 = useContractReader(readContracts, "USDG", "fieldsDepositValue", [address, 1], localProviderPollingTime);
  const field3 = useContractReader(readContracts, "USDG", "fieldsDepositValue", [address, 2], localProviderPollingTime);
  const field4 = useContractReader(readContracts, "USDG", "fieldsDepositValue", [address, 3], localProviderPollingTime);
  const field5 = useContractReader(readContracts, "USDG", "fieldsDepositValue", [address, 4], localProviderPollingTime);
  const field6 = useContractReader(readContracts, "USDG", "fieldsDepositValue", [address, 5], localProviderPollingTime);
  const fields = useMemo(() => {
    return [field1, field2, field3, field4, field5, field6];
  }, [field1, field2, field3, field4, field5, field6]);

  // 各矿池当前可投额度
  const performance1 = useContractReader(readContracts, "USDG", "performance", [address, 0], localProviderPollingTime);
  const performance2 = useContractReader(readContracts, "USDG", "performance", [address, 1], localProviderPollingTime);
  const performance3 = useContractReader(readContracts, "USDG", "performance", [address, 2], localProviderPollingTime);
  const performance4 = useContractReader(readContracts, "USDG", "performance", [address, 3], localProviderPollingTime);
  const performance5 = useContractReader(readContracts, "USDG", "performance", [address, 4], localProviderPollingTime);
  const performance6 = useContractReader(readContracts, "USDG", "performance", [address, 5], localProviderPollingTime);
  const performances = useMemo(() => {
    return [performance1, performance2, performance3, performance4, performance5, performance6];
  }, [performance1, performance2, performance3, performance4, performance5, performance6]);
  const formatCanInvestmentValue = useCallback(
    index => {
      const value =
        performances?.[index] && fields?.[index + 1]
          ? parseInt(
            (Number(utils.formatEther(performances[index]) / (index === 0 ? 5 : index * 10)) -
              Number(utils.formatEther(fields[index + 1]))) /
            1080,
          ) * 1080
          : "...";
      if (typeof value === "number") {
        if (value < 0) {
          return 0;
        }
      }
      return value;
    },
    [fields, performances],
  );
  const formatNewUsdtValue = useCallback(
    index => {
      return fields?.[index] ? utils.formatEther(fields[index]) : "...";
    },
    [fields],
  );
  const formatRevenueValue = useCallback(
    index => {
      return fields?.[index] ? utils.formatEther(fields[index]) * (index + 2) : "...";
    },
    [fields],
  );

  const formatPerformanceValue = useCallback(
    index => {
      return performances?.[index] ? utils.formatEther(performances[index]) : "...";
    },
    [performances],
  );

  useEffect(() => {
    const fetch = async () => {
      try {
        if (readContracts?.USDG?.registerState) {
          const registerStateResult = await readContracts?.USDG?.revenueCalculate(address);
          setRevenueCalculate(Number(utils.formatEther(registerStateResult.toString())).toFixed(2));
        }
      } catch (e) { }
    };
    const fetchUserSpeedUpCards = async () => {
      try {
        const userSpeedUpCards = await Promise.all(
          Array.from({ length: 6 }, (item, index) => index).map(index => {
            return readContracts?.USDG?.userSpeedUpCard(address, index);
          }),
        );
        setUserSpeedUpCards(userSpeedUpCards);
      } catch (e) { }
    };
    const fetchUserSpeedUpPools = async () => {
      try {
        const userSpeedUpPools = await Promise.all(
          Array.from({ length: 6 }, (item, index) => index).map(index => {
            return readContracts?.USDG?.userSpeedUpPool(address, index);
          }),
        );
        setUserSpeedUpPools(userSpeedUpPools);
      } catch (e) { }
    };
    let interval = setInterval(() => {
      fetch();
      fetchUserSpeedUpCards();
      fetchUserSpeedUpPools();
    }, 6000);

    return () => {
      window.clearInterval(interval);
    };
  }, [address, readContracts]);
  return (
    <div>
      <div className="mainWrapper" />
      <Row className="mainHeaderWrapper" align="middle" justify="space-between">
        <Col>
          <img src={logo} alt="logo" className="logoIcon" />
        </Col>
        <Col>
          <Row align="middle" gutter={10}>
            <Col>
              <Button
                icon={<ShareAltOutlined color="#fff" />}
                type="dashed"
                style={{ fontSize: 18, height: 46, display: "flex", alignItems: "center" }}
              >
                <Typography.Text copyable={{ text: `${window.location.origin}/?shareFrom=${address}` }}>
                  {t("分享链接")}
                </Typography.Text>
              </Button>
            </Col>
            <Col>
              <NavLanguageSelector />
            </Col>
            {/* <Col>
              <Row className="walletWrapper" gutter={5} align="middle">
                <>
                  <Col>
                    <img src={wallet} alt="wallet" className="walletIcon" />
                  </Col>
                  <Col>
                    <span>连接钱包</span>
                  </Col>
                </>
                <span>{displayAddress}</span>
              </Row>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <div className="paddingWrapper">
        <div className="accountRow">
          <Row className="addressRow" gutter={5}>
            <Col>
              <Typography.Text copyable={{ text: address }}>
                <a target="_blank" href={blockExplorerLink(address, blockExplorer)} rel="noopener noreferrer">
                  <span className="accountAddress">
                    {t("账户地址")}: {displayAddress}
                  </span>
                </a>
              </Typography.Text>
              {/* <span className="accountAddress">账户地址:{displayAddress}</span> */}
            </Col>
            {/* <Col>
              <img src={copyIcon} className="copyIcon" alt="copyIcon" />
            </Col> */}
          </Row>
          <Row gutter={5} style={{ marginTop: 10 }}>
            <Col>
              <span className="accountBalance">
                {t("账户USDT余额")}: {formatUSDTValue(usdtBalance)}
              </span>
            </Col>
            {/* <Col>
              <img src={swapIcon} className="copyIcon" alt="copyIcon" />
            </Col> */}
          </Row>
          {revenueCalculate > 0 && (
            <Row gutter={5} style={{ marginTop: 10 }}>
              <Col>
                <span className="accountBalance">
                  {t("未收割的USDG")}: {revenueCalculate}
                </span>
              </Col>
            </Row>
          )}
        </div>

        {/* USDT没有授权给USDG合约才展示 */}
        {!isApproved && (
          <Row className="approveWrapper">
            <Button
              style={{
                background: "rgba(21, 42, 32, 0.8)",
                borderRadius: 6,
                border: "1px solid #04ffd2",
                width: "100%",
                color: "#fff",
                fontSize: 16,
                height: 50,
              }}
              loading={approveLoading}
              onClick={async () => {
                const USDGAddress = readContracts.USDG.address;
                let hasError = false;
                try {
                  setApproveLoading(true);
                  const result = await tx(
                    writeContracts.USDT.approve(USDGAddress, ethers.constants.MaxUint256),
                    update => {
                      console.log("📡 Transaction Update:", update);
                      if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                          " ⛽️ " +
                          update.gasUsed +
                          "/" +
                          (update.gasLimit || update.gas) +
                          " @ " +
                          parseFloat(update.gasPrice) / 1000000000 +
                          " gwei",
                        );
                      }
                      if (update && !update.hash) {
                        hasError = true;
                      }
                    },
                  );
                  console.log("awaiting metamask/web3 confirm result...", result);
                } catch (e) {
                  hasError = true;
                } finally {
                  setApproveLoading(false);
                  if (!hasError) {
                    notification.success({
                      message: t("授权批准USDT成功"),
                    });
                  }
                }
              }}
            >
              {t("授权批准USDT")}
            </Button>
          </Row>
        )}

        {/*  ===========投资============ */}
        <div className="titleWrapper">
          <span className="titleText investText">{t("投资")}</span>
          <div className="bottomLine" />
        </div>
        <Row gutter={2}>
          <Col span={16}>
            <InputNumber
              placeholder={t("请输入您要投资的USDT数量")}
              style={{
                background: "rgba(21,42,32,0.8)",
                width: "100%",
                border: "1px solid #04FFD2",
                color: "#fff",
                fontSize: 28,
              }}
              min={1080}
              step={1080}
              value={newUsdtAmount}
              onChange={value => {
                setNewUsdtAmount(value);
              }}
            />
          </Col>
          <Col span={8}>
            <Select
              style={{
                background: "rgba(21,42,32,0.8)",
                width: "100%",
                fontSize: 28,
                // border: "1px solid #04FFD2",
              }}
              value={depositField}
              onChange={value => {
                setDepositField(value);
              }}
              suffixIcon={() => (
                <div className="arrowIconWrapper">
                  <img src={arrowIcon} className="arrowIcon" alt="arrowIcon" />
                </div>
              )}
              options={[
                {
                  value: 1,
                  label: `1. ${t("黑铁")}`,
                },
                ...pools.map((item, index) => ({
                  value: index + 2,
                  label: `${index + 2}. ${item}`,
                })),
              ]}
            />
          </Col>
        </Row>

        <Row
          justify="space-between"
          style={{
            marginTop: 5,
          }}
        >
          <Col className="inputTipRedText">
            <span>*{t("必须为1080的倍数")}</span>
          </Col>
          <Col className="inputTipRedText">
            <span>{t("选收益池")}</span>
          </Col>
        </Row>

        <Row className="approveWrapper">
          <Button
            style={{
              background: "rgba(21, 42, 32, 0.8)",
              borderRadius: 6,
              border: "1px solid #04ffd2",
              width: "100%",
              color: "#fff",
              fontSize: 16,
              height: 50,
            }}
            loading={newUsdtLoading}
            onClick={async () => {
              let hasError = false;
              try {
                if (!newUsdtAmount) {
                  notification.warning({
                    message: t("请输入USDT数量"),
                  });
                  hasError = true;
                  return;
                }
                if (newUsdtAmount > usdtBalance) {
                  notification.warning({
                    message: t("USDT余额不足"),
                  });
                  hasError = true;
                  return;
                }
                if (newUsdtAmount % 1080 !== 0) {
                  notification.warning({
                    message: t("请输入1080的倍数"),
                  });
                  hasError = true;
                  return;
                }
                setNewUsdtLoading(true);
                /* look how you call setPurpose on your contract: */
                /* notice how you pass a call back for tx updates too */
                const depositValue = utils.parseEther(newUsdtAmount.toString());
                const result = await tx(
                  writeContracts.USDG.deposit(depositValue, (depositField - 1).toString()),
                  update => {
                    console.log("📡 Transaction Update:", update);
                    if (update && (update.status === "confirmed" || update.status === 1)) {
                      console.log(" 🍾 Transaction " + update.hash + " finished!");
                      console.log(
                        " ⛽️ " +
                        update.gasUsed +
                        "/" +
                        (update.gasLimit || update.gas) +
                        " @ " +
                        parseFloat(update.gasPrice) / 1000000000 +
                        " gwei",
                      );
                    }
                    if (update && !update.hash) {
                      hasError = true;
                    }
                  },
                );

                console.log("awaiting metamask/web3 confirm result...", result);
              } catch (e) {
                hasError = true;
              } finally {
                setNewUsdtLoading(false);
                if (!hasError) {
                  notification.success({
                    message: `${t("质押")} ${newUsdtAmount} USDT ${t("成功")}`,
                  });
                }
              }
            }}
          >
            {t("GO!")}
          </Button>
        </Row>

        {/* "帐户概览" */}
        <div className="titleWrapper">
          <span className="titleText">{t("USDG账户")}</span>
        </div>

        <Row gutter={[10, 10]}>
          <Col span={12}>
            <div className="cardItem">
              <div className="cardNumberText">{formatDisplayValue(deposit)}</div>
              <div className="cardTipText">{t("总投入")}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="cardItem">
              <div className="cardNumberText">{formatDisplayValue(totalRevenue)}</div>
              <div className="cardTipText">{t("总收益")}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="cardItem">
              <div className="cardNumberText">{formatDisplayValue(totalclaimedRevenue)}</div>
              <div className="cardTipText">{t("已收割收益")}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="cardItem">
              <div className="cardNumberText">{formatDisplayValue(usdgBalance)}</div>
              <div className="cardTipText">{t("当前持有美元")}</div>
            </div>
          </Col>
        </Row>

        {/* "帐户概览" */}
        <div className="titleWrapper">
          <span className="titleText">{t("直推返佣奖励")}</span>
        </div>

        <Row gutter={[10, 10]}>
          <Col span={12}>
            <div className="cardItem">
              <div className="cardNumberText">{formatDisplayValue(usdtShare)}</div>
              <div className="cardTipText">{t("USDT直推返佣")}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="cardItem">
              <div className="cardNumberText">{formatDisplayValue(usdgShare)}</div>
              <div className="cardTipText">{t("USDG直推返佣")}</div>
            </div>
          </Col>
          <Col span={12}>
            <Button
              style={{
                background: "rgba(21, 42, 32, 0.8)",
                borderRadius: 6,
                border: "1px solid #04ffd2",
                width: "100%",
                color: "#fff",
                fontSize: 16,
                height: 50,
              }}
              loading={usdtclaimLoading}
              onClick={async () => {
                let hasError = false;
                try {
                  setUsdtClaimLoading(true);
                  /* look how you call setPurpose on your contract: */
                  /* notice how you pass a call back for tx updates too */
                  const result = await tx(writeContracts.USDG.takeUSDTShareReward(), update => {
                    console.log("📡 Transaction Update:", update);
                    if (update && (update.status === "confirmed" || update.status === 1)) {
                      console.log(" 🍾 Transaction " + update.hash + " finished!");
                      console.log(
                        " ⛽️ " +
                        update.gasUsed +
                        "/" +
                        (update.gasLimit || update.gas) +
                        " @ " +
                        parseFloat(update.gasPrice) / 1000000000 +
                        " gwei",
                      );
                    }
                    if (update && !update.hash) {
                      hasError = true;
                    }
                  });

                  console.log("awaiting metamask/web3 confirm result...", result);
                } catch (e) {
                  hasError = true;
                } finally {
                  if (!hasError) {
                    notification.success({
                      message: t("收割成功"),
                    });
                  }
                  setUsdtClaimLoading(false);
                }
              }}
            >
              {t("提取USDT返佣")}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              style={{
                background: "rgba(21, 42, 32, 0.8)",
                borderRadius: 6,
                border: "1px solid #04ffd2",
                width: "100%",
                color: "#fff",
                fontSize: 16,
                height: 50,
              }}
              loading={usdgclaimLoading}
              onClick={async () => {
                let hasError = false;
                try {
                  setUsdgClaimLoading(true);
                  /* look how you call setPurpose on your contract: */
                  /* notice how you pass a call back for tx updates too */
                  const result = await tx(writeContracts.USDG.takeUSDGShareReward(), update => {
                    console.log("📡 Transaction Update:", update);
                    if (update && (update.status === "confirmed" || update.status === 1)) {
                      console.log(" 🍾 Transaction " + update.hash + " finished!");
                      console.log(
                        " ⛽️ " +
                        update.gasUsed +
                        "/" +
                        (update.gasLimit || update.gas) +
                        " @ " +
                        parseFloat(update.gasPrice) / 1000000000 +
                        " gwei",
                      );
                    }
                    if (update && !update.hash) {
                      hasError = true;
                    }
                  });

                  console.log("awaiting metamask/web3 confirm result...", result);
                } catch (e) {
                  hasError = true;
                } finally {
                  if (!hasError) {
                    notification.success({
                      message: t("收割成功"),
                    });
                  }
                  setUsdgClaimLoading(false);
                }
              }}
            >
              {t("提取USDG返佣")}
            </Button>
          </Col>
        </Row>

        {/* ===========各矿池当前可投额度============ */}
        <div className="titleWrapper">
          <span className="titleText">{t("各矿池当前可投额度")}</span>
          <div className="bottomLine" />
        </div>

        <Row gutter={[10, 10]}>
          <Col span={8}>
            <div className="cardItem">
              <div className="cardNumberText">{t("不限")}</div>
              <div className="cardTipText">{t("黑铁")}</div>
            </div>
          </Col>
          {pools.map((pool, index) => {
            return (
              <Col span={8} key={index}>
                <div className="cardItem">
                  <div className="cardNumberText">{formatCanInvestmentValue(index)}</div>
                  <div className="cardTipText">{pool}</div>
                </div>
              </Col>
            );
          })}
        </Row>


        {/* ===========各矿池投资加速卡概览============ */}
        <div className="titleWrapper">
          <span className="titleText">{t("各矿池加速卡概览")}</span>
          <div className="bottomLine" />
        </div>

        <Row gutter={[10, 10]}>
          {[t("黑铁"), ...pools].map((pool, index) => {
            return (
              <Col span={8} key={index}>
                <div className="cardItem" style={{ color: "#fff" }}>
                  {/* <div className="cardNumberText">{formatNewUsdtValue(index)}</div>
                  <div className="cardTipText">{pool}</div> */}
                  <span>
                    {t("加速卡")}
                    {index + 1}
                  </span>
                  <span>
                    {t("每日加速")}:{formatDisplayValue(userSpeedUpCards?.[index]?.speedUpPerDay?.toString())}
                  </span>
                  <span>
                    {t("开始时间")}:{formatDatetime(userSpeedUpCards?.[index]?.startTime?.toString() * 1000)}
                  </span>
                  <span>
                    {t("结束时间")}:{formatDatetime(userSpeedUpCards?.[index]?.endTime?.toString() * 1000)}
                  </span>
                  {userSpeedUpCards?.[index]?.activeState ? (
                    <>
                      <img style={{ width: 62, height: 70 }} src={powerIcon} alt="power" />
                    </>
                  ) : (
                    <img style={{ width: 62, height: 70 }} src={powerNoIcon} alt="power" />
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
        {/* ===========各矿池投资加速池概览============ */}
        <div className="titleWrapper">
          <span className="titleText">{t("各矿池加速池概览")}</span>
          <div className="bottomLine" />
        </div>
        <Row gutter={[10, 10]}>
          {[t("黑铁"), ...pools].map((pool, index) => {
            return (
              <Col span={8} key={index}>
                <div className="cardItem" style={{ color: "#fff" }}>
                  {/* <div className="cardNumberText">{formatNewUsdtValue(index)}</div>
                  <div className="cardTipText">{pool}</div> */}
                  <span>
                    {t("加速池")}
                    {index + 1}
                  </span>
                  <span>
                    {t("每日加速")}:{formatDisplayValue(userSpeedUpPools?.[index]?.speedUpPerDay?.toString())}
                  </span>
                  <span>
                    {t("截止时间")}:{formatDatetime(userSpeedUpPools?.[index]?.endTime?.toString() * 1000)}
                  </span>

                  {
                    <Spin spinning={speedLoading} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}>
                      <img
                        src={
                          userSpeedUpPools?.[index]?.endTime?.toString() * 1000 < Date.now()
                            ? batteryNoIcon
                            : batteryIcon
                        }
                        alt="battery"
                        style={{
                          width: 91,
                          height: 37,
                          cursor: "pointer",
                        }}
                        onClick={async () => {
                          let hasError = false;
                          try {
                            setSpeedLoading(true);
                            /* look how you call setPurpose on your contract: */
                            /* notice how you pass a call back for tx updates too */
                            const result = await tx(writeContracts.USDG.chargeSpeedUpCard(index), update => {
                              console.log("📡 Transaction Update:", update);
                              if (update && (update.status === "confirmed" || update.status === 1)) {
                                console.log(" 🍾 Transaction " + update.hash + " finished!");
                                console.log(
                                  " ⛽️ " +
                                  update.gasUsed +
                                  "/" +
                                  (update.gasLimit || update.gas) +
                                  " @ " +
                                  parseFloat(update.gasPrice) / 1000000000 +
                                  " gwei",
                                );
                              }
                              if (update && !update.hash) {
                                hasError = true;
                              }
                            });

                            console.log("awaiting metamask/web3 confirm result...", result);
                            // console.log(newUsdtAmount,depositField)
                          } catch (e) {
                            hasError = true;
                          } finally {
                            if (!hasError) {
                              notification.success({
                                message: t("加速成功"),
                              });
                            }
                            setSpeedLoading(false);
                          }
                        }}
                      />
                    </Spin>
                  }
                  <span>
                    （
                    {userSpeedUpPools?.[index]?.endTime?.toString() * 1000 < Date.now()
                      ? t("当前不可加速")
                      : t("点击加速")}
                    ）
                  </span>
                </div>
              </Col>
            );
          })}
        </Row>

        {/* ===========各矿池投资额概览============ */}
        <div className="titleWrapper">
          <span className="titleText">{t("各矿池投资额概览")}</span>
          <div className="bottomLine" />
        </div>

        <Row gutter={[10, 10]}>
          {[t("黑铁"), ...pools].map((pool, index) => {
            return (
              <Col span={8} key={index}>
                <div className="cardItem">
                  <div className="cardNumberText">{formatNewUsdtValue(index)}</div>
                  <div className="cardTipText">{pool}</div>
                </div>
              </Col>
            );
          })}
        </Row>

        {/* ===========各矿池总收益额概览============ */}
        <div className="titleWrapper">
          <span className="titleText">{t("各矿池总收益额概览")}</span>
          <div className="bottomLine" />
        </div>

        <Row gutter={[10, 10]}>
          {[t("黑铁"), ...pools].map((pool, index) => {
            return (
              <Col span={8} key={index}>
                <div className="cardItem">
                  <div className="cardNumberText">{formatRevenueValue(index)}</div>
                  <div className="cardTipText">{pool}</div>
                </div>
              </Col>
            );
          })}
        </Row>
        {/* ===========各代业绩============ */}
        <div className="titleWrapper">
          <span className="titleText">{t("各代业绩")}</span>
          <div className="bottomLine" />
        </div>

        <Row gutter={[10, 10]}>
          {rings.map((ring, index) => {
            return (
              <Col span={8} key={index}>
                <div className="cardItem">
                  <div className="cardNumberText">{formatPerformanceValue(index)}</div>
                  <div className="cardTipText">{ring}</div>
                </div>
              </Col>
            );
          })}
        </Row>

        <Row className="approveWrapper"
          style={{
            marginTop: "65px",
          }}>
          <Button
            style={{
              background: "rgba(21, 42, 32, 0.8)",
              borderRadius: 6,
              border: "1px solid #04ffd2",
              width: "100%",
              color: "#fbff00",
              fontSize: 16,
              height: 50,
            }}
            loading={claimLoading}
            onClick={async () => {
              let hasError = false;
              try {
                setClaimLoading(true);
                /* look how you call setPurpose on your contract: */
                /* notice how you pass a call back for tx updates too */
                const result = await tx(writeContracts.USDG.takeRevenue(), update => {
                  console.log("📡 Transaction Update:", update);
                  if (update && (update.status === "confirmed" || update.status === 1)) {
                    console.log(" 🍾 Transaction " + update.hash + " finished!");
                    console.log(
                      " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                    );
                  }
                  if (update && !update.hash) {
                    hasError = true;
                  }
                });

                console.log("awaiting metamask/web3 confirm result...", result);
                // console.log(newUsdtAmount,depositField)
              } catch (e) {
                hasError = true;
              } finally {
                if (!hasError) {
                  notification.success({
                    message: t("收割成功"),
                  });
                }
                setClaimLoading(false);
              }
            }}
          >
            {t("收割USDG收益")}({revenueCalculate} USDG)
          </Button>
        </Row>
        {/* ===========兑换============ */}
        <div className="titleWrapper">
          <span className="titleText">{t("申请兑换USDG为USDT")}</span>
          <div className="bottomLine" />
        </div>
        <Row gutter={2}>
          <Col span={18}>
            <InputNumber
              value={newUsdgAmount}
              min={120}
              step={120}
              placeholder={t("请输入您要兑换的USDG数量")}
              style={{
                background: "rgba(21,42,32,0.8)",
                width: "100%",
                border: "1px solid #04FFD2",
                color: "#fff",
                fontSize: 28,
              }}
              onChange={value => {
                setNewUsdgAmount(value);
              }}
            />
          </Col>
          <Col span={6}>
            <Row className="swapUsdtWrapper" justify="space-between" align="middle">
              <Col>
                <span className="swapUsdtAmountText">{newUsdgAmount}</span>
              </Col>
              <Col>
                <span className="swapUsdText">USDT</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{
            marginTop: 5,
          }}
        >
          <Col className="inputTipRedText">
            <span>*{t("必须为120的倍数")}</span>
          </Col>
        </Row>

        <Row className="approveWrapper">
          <Button
            style={{
              background: "rgba(21, 42, 32, 0.8)",
              borderRadius: 6,
              border: "1px solid #04ffd2",
              width: "100%",
              color: "#fff",
              fontSize: 16,
              height: 50,
            }}
            loading={swapLoading}
            onClick={async () => {
              console.log(applierState, "applierState");
              // eslint-disable-next-line eqeqeq
              if (applierState != 0) {
                notification.warning({
                  message: t("有订单在申请中"),
                });
                return;
              }
              if (!newUsdgAmount) {
                notification.warning({
                  message: t("请输入USDG数量"),
                });
                return;
              } else if (newUsdgAmount % 120 !== 0) {
                notification.warning({
                  message: t("请输入120的倍数"),
                });
                return;
              } else if (newUsdgAmount > utils.formatEther(usdgBalance)) {
                notification.warning({
                  message: t("USDG余额不足"),
                });
                return;
              }
              /* look how you call setPurpose on your contract: */
              /* notice how you pass a call back for tx updates too */
              const swapAmount = utils.parseEther(newUsdgAmount.toString());
              let hasError = false;
              try {
                setSwapLoading(true);
                const result = await tx(writeContracts.USDG.applyForSwap(swapAmount), update => {
                  console.log("📡 Transaction Update:", update);
                  if (update && (update.status === "confirmed" || update.status === 1)) {
                    console.log(" 🍾 Transaction " + update.hash + " finished!");
                    console.log(
                      " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                    );
                  }
                  if (update && !update.hash) {
                    hasError = true;
                  }
                });
                console.log("awaiting metamask/web3 confirm result...", result);
              } catch (e) {
                hasError = true;
              } finally {
                if (!hasError) {
                  notification.success({
                    message: `${t("申请兑换")} ${newUsdgAmount} USDG ${t("成功")}`,
                  });
                }
                setSwapLoading(false);
              }
              // console.log(newUsdgAmount)
            }}
          >
            {t("兑换")}
          </Button>
        </Row>
      </div>
    </div >
  );
}

export default Main;
