import React from "react";
import { Row, Col, Select } from "antd";
import { Languages } from "../i18n/language-options";
import i18n from "i18next";
export default function NavLanguageSelector() {
  return (
    <Row align="middle" gutter={3}>
      {/* <Col>
        <GlobalOutlined color="#fff" />
      </Col> */}
      <Col>
        <Select
          value={i18n.language}
          onChange={value => {
            i18n.changeLanguage(value);
          }}
          options={Object.keys(Languages).map(key => {
            return {
              value: key,
              label: Languages[key].long,
            };
          })}
        ></Select>
      </Col>
    </Row>
  );
}
