import { useEffect } from "react";
import { useHistory } from "react-router-dom";
export default function Auth({ children, address }) {
  const history = useHistory();
  useEffect(() => {
    if (!address) {
      history.replace("/");
    }
  }, [address, history]);
  return <>{address && children}</>;
}
