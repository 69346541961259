import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./index.css";
import startBtnIcon from "../images/start_btn.png";
import { useHistory } from "react-router-dom";
import { Button, Input, Modal, Row, Col, notification, Alert } from "antd";
import { useTranslation } from "react-i18next";
import NavLanguageSelector from "../components/NavLanguageSelector";
import { useLocation } from "react-router-dom";

const ethAddressReg = /^0x[a-fA-F0-9]{40}$/g;
export default function App({
  loadWeb3Modal,
  useContractReader,
  readContracts,
  address,
  localProviderPollingTime,
  tx,
  writeContracts,
  registerState,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [clickTimes, setClickTimes] = useState(0);
  const [fatherAddress, setFatherAddress] = useState();
  const [btnLoading, setBtnLoading] = useState();
  const location = useLocation();
  const shareFrom = useMemo(() => {
    return new URLSearchParams(location.search).get("shareFrom");
  }, [location.search]);
  useEffect(() => {
    if (address && registerState) {
      history.push("/main");
    } else if (address && !registerState && registerState !== undefined) {
      // const shareFrom = new URLSearchParams(location.search).get("shareFrom");
      console.log(shareFrom, "shareFrom");
      if (shareFrom && ethAddressReg.test(shareFrom)) {
        setRegisterModalVisible(true);
        setFatherAddress(shareFrom);
      }
    }
  }, [address, history, registerState, location.search, shareFrom]);

  useEffect(() => {
    if (registerModalVisible) {
      setClickTimes(0);
    }
  }, [registerModalVisible]);

  const handleRegister = useCallback(
    async fatherAddress => {
      setBtnLoading(true);
      let hasError = false;
      try {
        const result = await tx(writeContracts.USDG.register(fatherAddress), update => {
          console.log("📡 Transaction Update:", update);
          if (update && (update.status === "confirmed" || update.status === 1)) {
            console.log(" 🍾 Transaction " + update.hash + " finished!");
            console.log(
              " ⛽️ " +
                update.gasUsed +
                "/" +
                (update.gasLimit || update.gas) +
                " @ " +
                parseFloat(update.gasPrice) / 1000000000 +
                " gwei",
            );
          }
          if (update && !update.hash) {
            hasError = true;
          }
        });
        console.log(result);
      } catch (e) {
        hasError = true;
        setClickTimes(0);
      } finally {
        if (!hasError) {
          notification.success({
            message: t("注册成功"),
          });
          history.push("/main");
        }
        setBtnLoading(false);
      }
    },
    [history, t, tx, writeContracts.USDG],
  );

  return (
    <>
      <Row
        justify="end"
        style={{
          margin: 15,
        }}
      >
        <NavLanguageSelector />
      </Row>
      <div className="homeBackground"></div>
      <div
        className="homeStartBtnWrapper"
        onClick={() => {
          if (address && registerState) {
            history.push("/main");
          }
          if (address && !registerState) {
            setRegisterModalVisible(true);
          } else if (!address) {
            loadWeb3Modal();
          }
        }}
      >
        <img src={startBtnIcon} className="homeStartBtnImage" alt="start-btn" />
      </div>
      <Modal centered className="modalWrapper" visible={registerModalVisible} footer={null} closeIcon={() => null}>
        {/* <div className="modalBg"></div> */}
        <Input
          style={{
            height: 49,
          }}
          value={fatherAddress}
          placeholder={t("请绑定推荐人地址注册")}
          onChange={e => {
            setFatherAddress(e.target.value);
          }}
        />
        {!shareFrom && (
          <Row style={{ marginTop: 10 }} gutter={[10, 10]} direction="vertical" justify="space-between">
            <Col span={24}>
              <Alert message={t("若无推荐人，请选择所属地区")} type="warning" />
            </Col>
            <Col span={12}>
              <Button
                style={{
                  height: 47,
                  width: "100%",
                }}
                onClick={() => {
                  setFatherAddress("0x70d90EbabA06Ca7f9B7d7D4a06779861fEE06B10");
                }}
              >
                {t("北美团队")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                style={{
                  height: 47,
                  width: "100%",
                }}
                onClick={() => {
                  setFatherAddress("0xFd88d79CA6C41b9ae66697712169BF9CB072127E");
                }}
              >
                {t("欧洲团队")}
              </Button>
            </Col>
            <Col span={24}>
              <Button
                style={{
                  height: 47,
                  width: "100%",
                }}
                onClick={() => {
                  setFatherAddress("0x6a4440DE30cb3305c8CcF264348A20041926cF00");
                }}
              >
                {t("亚洲团队")}
              </Button>
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: 25 }} gutter={14}>
          <Col span={12}>
            <Button
              style={{
                width: "100%",
                height: 47,
                background: "none",
                color: "#fff",
                border: "1px solid #00FFFF",
              }}
              className="btnStyle"
              onClick={() => {
                setRegisterModalVisible(false);
              }}
            >
              {t("取消")}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              loading={btnLoading}
              style={{
                width: "100%",
                height: 47,
                background: "none",
                border: "1px solid #00FFFF",
                color: "#fff",
              }}
              className="btnStyle"
              onClick={() => {
                if (/^0x[a-fA-F0-9]{40}$/g.test(fatherAddress)) {
                  handleRegister(fatherAddress);
                } else {
                  notification.warn({
                    message: t("请输入正常的地址"),
                  });
                  setClickTimes(0);
                  return;
                }
                if (clickTimes === 1 && !fatherAddress) {
                  handleRegister("0x0000000000000000000000000000000000000000");
                }
                setClickTimes(time => {
                  return time + 1;
                });
              }}
            >
              {t("确定")}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
